<template>
  <div class="confirm-page">
    <div class="page-main bg-white">
      <div v-if="exam" class="confirm-main">
        <div class="container">
          <div class="name">{{ exam.exam_name }}</div>
        </div>
        <div class="container">
          <div class="cate_title title-icon">考试信息</div>
          <div class="flex info">
            <div class="item">
              <div class="value">{{ getTime(exam.exam_duration) }}</div>
              <div class="label">考试时间（分钟）</div>
            </div>
            <div class="line">|</div>
            <div class="item">
              <div class="value">{{ exam.exam_question_num }}</div>
              <div class="label">题目数量（题）</div>
            </div>
            <div class="line">|</div>
            <div class="item">
              <div class="value">{{ exam.exam_total_score }}</div>
              <div class="label">试卷总分（分）</div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="cate_title title-icon">考试详情</div>
          <div class="flex detail">
            <div class="left">考试时间：</div>
            <div class="content">
              {{ exam.exam_start_time }}至{{ exam.exam_end_time }}
              <div>超过规定时间就不可以参加考试</div>
            </div>
          </div>
          <div class="flex detail">
            <div class="left">通过分数：</div>
            <div class="content" style="color: #bb2f2f">
              {{ exam.exam_pass_score }}分
            </div>
          </div>
          <div class="flex detail">
            <div class="left">考试次数：</div>
            <div class="content">
              本场考试您可参与{{
                exam.exam_sum == -1 ? "不限" : exam.exam_sum
              }}次
            </div>
          </div>
          <div class="flex detail">
            <div class="left">注意事项：</div>
            <div class="content" v-html="exam.exam_content"></div>
          </div>
        </div>
        <a-button
          type="primary"
          size="large"
          block
          class="start-btn"
          @click="startExam()"
          >开始考试</a-button
        >
        <!-- <u-modal
          :show="show"
          :title="title"
          :content="content"
          :showCancelButton="true"
          @confirm="startExam"
          @cancel="cancelExam"
        ></u-modal> -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, createVNode } from "vue";
import { Modal } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
// import { FireTwoTone, VideoCameraOutlined } from "@ant-design/icons-vue";
import url from "@/utils/url.js";

export default defineComponent({
  name: "Confirm",
  //   components: { ExclamationCircleOutlined },
  setup() {
    const pageData = reactive({
      exam: {
        exam_name: "放假的快乐就是狂蜂浪蝶设计费",
        exam_duration: 1220,
        exam_question_num: 10,
        exam_total_score: 10,
        exam_start_time: "2022-10-11",
        exam_end_time: "2022-10-11",
        exam_pass_score: 10,
        exam_sum: 1,
        exam_content: "ddfdfdsfd",
      },
    });
    const getTime = (time) => {
      let hours = time >= 3600 ? Math.floor(time / 3600) : "";
      let minutes = time > 60 ? Math.floor((time % 3600) / 60) : "";
      let seconds = (time % 3600) % 60;
      return `${hours.toString().padStart(2, 0)}时${minutes
        .toString()
        .padStart(2, 0)}分${seconds.toString().padStart(2, 0)}秒`;
    };
    const startExam = () => {
      Modal.confirm({
        title: "考试提醒",
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode(
          "div",
          {
            style: "color:red;",
          },
          "考试开始后即消耗考试次数，中途不可退出，是否开始考试？"
        ),

        onOk() {
          url.navigateTo("/exam/exam", {});
        },

        onCancel() {
          console.log("Cancel");
        },
      });
    };

    return { ...toRefs(pageData), getTime, startExam };
  },
});
</script>

<style lang="less" scoped>
.confirm-page {
  height: 100%;
  .confirm-main {
    padding: 30px 0 60px;
    width: 600px;
    margin: 0 auto;
  }
}
.container {
  padding: 18px 0px;
  .cate_title {
    margin-bottom: 24px;
    font-size: 16px;
  }
  .name {
    color: #333333;
    font-size: 24px;
  }

  .info {
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    .item {
      width: 224px;
      text-align: center;
    }

    .value {
      color: #333333;
    }

    .label {
      color: #969696;
      font-size: 14px;
      margin-top: 30px;
    }

    .line {
      color: #eeeeee;
    }
  }

  .detail {
    margin-bottom: 30px;
    font-size: 16px;

    .left {
      color: #969696;
      width: 150px;
    }

    .content {
      color: #333333;
      flex: 1;
    }
  }
}

.title-icon {
  position: relative;
  padding-left: 12px;
}
.title-icon::after {
  content: "";
  display: block;
  width: 4px;
  height: 100%;
  border-radius: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #1890ff;
}
</style>
